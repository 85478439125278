export function removeGaps(obj: any) {
    // Get the max length among x axes
	let maxLength = 0;

	obj.numdatas.forEach((it: any) => {
		if (it.xaxisdata.length > maxLength) {
			maxLength = it.xaxisdata.length;
		}
	});

	// Get array with all dates available
	const standardArrayXvalues = obj.numdatas.find((it: any) => it.xaxisdata.length === maxLength);

	// Compare all xaxisdata with standardArrayXvalues and adjust to all have the same length. 
	// If there is value date, get date; if there is not value, get empty string
	const resultXvalues = obj.numdatas.map((it: any) => {
		const { xaxisdata } = it;
		return standardArrayXvalues?.xaxisdata.map((data: any) => xaxisdata.includes(data) ? data : '')
	});
		
	// Fill the x axis with the new values from resultXvalues
	obj.numdatas.forEach((it: any, idx: number) => {
		it.xaxisdata = resultXvalues[idx];
	});

	// Fill the y axis to leave the same length of x axis and according your own date
	for (let i = 0; i < obj.numdatas.length; i++) {
		const currentData = obj.numdatas[i];

		let aux = 0;
		const adjustedYaxis = currentData.xaxisdata.map((xValue: string) => {
			let yValue;
			if (xValue !== '') {
				yValue = currentData.yaxisdata[aux];
				aux++
				return yValue;
			}
			return 0;
		});

		currentData.yaxisdata = adjustedYaxis;
	}

	// Set the x axis with date, no empty string
	obj.numdatas.forEach((it: any) => {
		it.xaxisdata = standardArrayXvalues?.xaxisdata;
	});

	return obj;
}