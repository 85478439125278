import React, { useState } from 'react'

import cn from 'classnames'

import { ReactComponent as MetricsIcon } from "../../../assets/stations/metrics-icon.svg"
import { ReactComponent as ChevronUp } from "../../../assets/stations/chevron-up.svg"
import { ReactComponent as ChevronDown } from "../../../assets/stations/chevron-down.svg"

import { E5RequestMeta } from '../../../request/E5RequestMeta';


import './E5StationsPage.css'
import { E5EntHStation } from '../../../entity/household/topo/E5EntHStation'
import { E5Text } from '../../../util/E5Text'
// import { E5UtilI18n } from '../../../global/E5MainLang'
import { E5BandEnum } from '../../../entity/E5Enums'
import { E5EntHIncidWifi } from '../../../entity/household/incid/E5EntHIncidWifi'
import { E5StoreH, FingerprintResult } from '../../../store/E5StoreH'
import { E5Store } from '../../../store/E5Store'
import { useHistory } from 'react-router'
import { E5Page, E5Path } from '../../../global/E5MainStatics'
import { E5UtilI18n } from '../../../global/E5MainLang'
import { Position, Popover, PopoverInteractionKind } from '@blueprintjs/core'

interface IProps {
    station: E5EntHStation;
    equip?: string;
    fingerprint?: FingerprintResult
}

const bandshash: any = {
    [E5BandEnum.eth]: true,
    [E5BandEnum.eth100]: true,
    [E5BandEnum.eth1000]: true,
    [E5BandEnum.freq2ghz]: true,
    [E5BandEnum.freq5ghz]: true,
    [E5BandEnum.freq6ghz]: true,
}

// const HealthBadge: React.FC <{score:number}> = ({score}) => {
//     return <div className='health-badge'>

//     </div>
// }


const E5HStationItem: React.FC<IProps> = ({ station, equip, fingerprint }) => {
    // force rerender when lang changes
    const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
    
    const history = useHistory();
    const [showDetails, setShowDetails] = useState(false)

    // const {_} = E5UtilI18n;

    const isEth = station.wifistdavailable.length === 0 && station.wifistdused.length === 0;
    const deviceType = fingerprint?.deviceType ? fingerprint?.deviceType : 'Unknown';
    const showConfidenceInCategory = fingerprint?.mlUsed?.toLowerCase() === "yes" && fingerprint?.confidenceDeviceCategory?.toLowerCase() === "yes" && fingerprint?.confidence >= 45;
    const showConfidenceInDevice = fingerprint?.mlUsed?.toLowerCase() === "yes" && fingerprint?.confidenceDeviceType?.toLowerCase() === "yes" && fingerprint?.confidence >= 45;
    const generateStationImage = (deviceType: string) => {
        let deviceImgName = '';
        switch (deviceType) {
            case 'Smart device':
                deviceImgName = 'Smart_Device'
                break
            case 'Third Party AP':
                deviceImgName = 'Third_Party_AP'
                break
            case 'Printer':
                deviceImgName = 'printer'
                break
            case 'Computer':
                deviceImgName = 'laptop'
                break
            case 'Smartphone or tablet':
                deviceImgName = 'phone'
                break
            case 'Sound device':
                deviceImgName = 'soundsystem'
                break
            case 'Game console':
                deviceImgName = 'gameconsole'
                break
            case 'Multimedia device':
                deviceImgName = 'miltimedia-device'
                break
            case 'Wifi equipment':
                deviceImgName = 'wifi-equipment'
                break
            case 'IoT device':
                deviceImgName = 'IOT'
                break
            case 'Unknown':
                deviceImgName = "unknown"
                break

            default:
                deviceImgName = "unknown"
                break
        }
        const stationImageByFingerpring = "/img/nodes_and_devices/devices-by-fingerprint/" + deviceImgName + ".svg";
        return stationImageByFingerpring;
    }
    const imgSrc = generateStationImage(deviceType);
    const linkTypeString = station.linktype.toUpperCase() === "wifi".toUpperCase() ? "Wi-Fi" : station.linktype;
    const bandString = bandshash[station.band] !== undefined ? station.band : E5EntHIncidWifi.bandunknown;

    const Chevron = showDetails ? ChevronUp : ChevronDown;
    const handleClickMetrics = (e: any) => {
        e.stopPropagation();
        history.push(E5Path.Get(E5Page.H_METRICS) + `?station=${station.macaddress}&page=station`)
    }

    const HealthScore = ({ score }) => {
        let text = '';
        let color = '';

        if (score === null) {
            text = 'N/A';
            color = 'grey';

        } else {
            score = Math.round(score * 100);

            if (score >= 0 && score < 40) {
                text = 'Bad';
                color = 'red'
            }
            if (score >= 40 && score < 70) {
                text = 'Medium';
                color = '#EBBE4B'
            }
    
            if (score >= 70) {
                text = 'Excellent';
                color = '#19B98F'
            }
        }

        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '60px', height: '8px', borderRadius: '10px', backgroundColor: color, marginRight: '10px', marginTop: '2px' }}></div>
            <p style={{ margin: 0 }} color={color}>{text}</p>
        </div>

    };

    const CategoryStatus = ({ confidence }: { confidence: never }) => {
        let color = 'lightgray';
        if (confidence === 0) {
            color = 'red'
        }
        if (confidence > 0 && confidence <= 50) {
            color = '#EBBE4B'
        }

        if (confidence >= 50) {
            color = '#19B98F'
        }
        return <div style={{ width: '10px', height: '10px', backgroundColor: color, borderRadius: '50px', marginLeft: '15px' }}>

        </div>
    }

    let healthScore = station.health;
    let healthScoreText;
    let bandDevice = bandString;

    if (station.band === 'ETH') {
        healthScore = null;
        healthScoreText = E5UtilI18n._("not-applied");
        bandDevice = E5UtilI18n._("not-applied");
    }

    return <div className='stations-list-item'>
        <div className={cn('list-item-header', { 'closed-header': !showDetails })} onClick={() => setShowDetails(prev => !prev)}>
            <img src={imgSrc} alt='category' className='header-image' />
            <div className='header-title-container'>
                <div className='title-item'>
                    <span className='title-item-label'>Hostname : </span>
                    <div className='title-item-content'>{station.hostname}</div>
                </div>
                <div className='title-item'>
                    <span className='title-item-label'>MAC addr :</span>
                    <div className='title-item-content'>{station.macaddress}</div>
                </div>
                <div className='title-item'>
                    <span className='title-item-label'>Health score :</span>
                    <div className='title-item-content'>
                        {/* {Math.round(station.health * 100)} */}
                        <HealthScore score={(healthScore)} />
                    </div>
                </div>
            </div>
            <MetricsIcon className='header-metrics' onClick={handleClickMetrics} />
            <Chevron className='header-chevron' />
        </div>
        <div className={cn('list-item-content', { 'show-details': showDetails })}>
            <div className='top-content'>
                <div className='content-item'>
                    <span className='content-item-label'>
                        <span className='station-item-label-name'>Category</span>
                        {
                            showConfidenceInCategory ?
                            <Popover 
                                content={<span className="content-popover-item">{E5UtilI18n._("detectByAI")}</span>}
                                interactionKind={PopoverInteractionKind.HOVER}
                                position={Position.TOP}
                                usePortal={true}
                                portalContainer={document.querySelector('.stations-page') as HTMLElement}>
                                <div className='e5iconinfo e5cursorpointer station-icon-info'></div>
                            </Popover>
                            :
                            null
                        }
                    </span>
                    <div className='content-item-value'>{fingerprint?.deviceType && fingerprint?.deviceType?.toLowerCase() !== 'unknown' ? fingerprint?.deviceType : '-'}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>
                        <span className='station-item-label-name'>Device info</span>
                        {
                            showConfidenceInDevice ?
                            <Popover 
                                content={<span className="content-popover-item">{E5UtilI18n._("detectByAI")}</span>}
                                interactionKind={PopoverInteractionKind.HOVER}
                                position={Position.TOP}
                                usePortal={true}
                                portalContainer={document.querySelector('.stations-page') as HTMLElement}>
                                <div className='e5iconinfo e5cursorpointer station-icon-info'></div>
                            </Popover>
                            :
                            null
                        }
                    </span>
                    <div className='content-item-value'>{fingerprint?.deviceInfo && fingerprint?.deviceInfo?.toLowerCase() !== 'unknown' ? fingerprint?.deviceInfo : '-'}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Link type :</span>
                    <div className='content-item-value'>{linkTypeString || '-'}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Connected to :</span>
                    <div className='content-item-value'>{equip || '-'}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Band :</span>
                    <div className='content-item-value'>{bandDevice}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Last seen :</span>
                    <div className='content-item-value'>
                        {station.lastseen?.format("HH:mm:ss") ?? "N/A"}
                        {station.lastseen !== null
                            && E5StoreH.Ins().curdateinfo.curdate === E5Store.Ins().todayinfo.today &&
                            <span>&nbsp;<i>({station.lastseen.fromNow()})</i></span>
                        }</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Health score :</span>
                    <div className='content-item-value'>
                        { healthScore === null ? healthScoreText : E5Text.ScoreToPercent(healthScore) + '%'}
                        {/* <HealthScore /> */}
                    </div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Channel :</span>
                    <div className='content-item-value'>{station.channelinuse || '-'}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Max bit rate :</span>
                    <div className='content-item-value'>{station.maxbitrate} {isEth ? "Mb/s" : "kb/s"}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Wi-Fi available :</span>
                    <div className='content-item-value'>{isEth ? '-' : (station.wifistdavailable.join(", ") || '-')}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Wi-Fi used :</span>
                    <div className='content-item-value'>{isEth ? '-' : (station.wifistdused.join(", ") || '-')}</div>
                </div>
                <div className='content-item'>
                    <span className='content-item-label'>Bandwidth :</span>
                    <div className='content-item-value'>{station.curbandwidth || '-'}</div>
                </div>
            </div>
        </div>
    </div>
}

export default E5HStationItem