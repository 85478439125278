import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { DEFAULT_CHART_COLORS, E5XYChart, E5XYNumData, E5XYSource } from "../../../global/plot/E5XYChart";
import { E5AngularGauge } from "../../../global/plot/E5AngularGauge";
import { E5PieChart } from "../../../global/plot/E5PieChart";
import { E5CBOverviewIndic } from "./E5CBOverviewIndic";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5BandEnum } from "../../../entity/E5Enums";
import { E5StoreCB } from "../../../store/E5StoreCB";
import { E5Store } from "../../../store/E5Store";
import { E5CBDashboard } from "./E5CBDashboard";
import { E5Text } from "../../../util/E5Text";
import { removeGaps } from "../../../util/E5RemoveGapsOfDateChart"
//
import "./E5CBOverview.css";
import { E5StoreCBSys } from "../../../store/E5StoreCBSys";

//E5
interface E5CBOverviewState { }

//E5
interface E5CBOverviewProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
}

//E5
export const E5CBOverview = observer(class E5CBOverview extends React.PureComponent
	<E5CBOverviewProps, E5CBOverviewState> {

	// ---------------- MEMBERS ----------------

	static clarankmap: Map<string, string> = new Map();

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { global, wifi, wan } = E5StoreCB.Ins(), { health } = E5StoreCBSys.Ins().overview, { nilist, nilistsettings } = E5StoreCB.Ins(),
			{ mode, downloadstr, date, cla } = nilistsettings;


		let [sfwids, sfwparents, sfwvalues, sfwlabels] = E5CBOverview.GetSoftwarePieData(),
			[eqpids, eqpparents, eqpvalues, eqplabels] = E5CBOverview.GetEquipPieData(),
			[staids, staparents, stavalues, stalabels] = E5CBOverview.GetStationPieData();

		return <div className="e5cb-overview e5column-20">
			<div className="e5line-20">
				<div className="e5linefull e5column-0">
					<E5CBOverviewIndic />
					<E5AngularGauge gaugeinfo={
						{
							value: global.health, value2: wifi.health, value3: wan.health, value4: health,
							title: _("cb-health"), label: 'Total', label2: 'Wi-Fi', label3: 'WAN', label4: 'System', loading: false
						}} isNewComponent customChartClass='overview-progress-chart' customWrapperClass="overview-progress-wrapper" chartNewColorScheme
						canvasSmall30 />
				</div>
				<div className="e5linefull2">
					<div className="e5line-0 h-100">
						<BP.Popover className="e5linefull e5line-0" targetClassName="e5linefull"
							isOpen={mode === "health"} content={<div className="e5cb-dashboard-downloaddiv">
								<div className="title">{downloadstr}</div>
								{nilist.status.loading && <BP.Spinner className="e5spinwait" size={15} />}
								{!nilist.status.loading && <BP.Button
									text={E5UtilI18n._("download")} intent={BP.Intent.PRIMARY} autoFocus
									onClick={() => E5CBDashboard.DownloadNiListHealth("globalHealth", date, cla,
										E5CBOverview.clarankmap, this.props.toasterref)}
									onBlur={() => E5StoreCB.Ins().SetNiListSettings("none", "",
										undefined, undefined, undefined, undefined, undefined,
										undefined, undefined)} ref={this.props.downloadref} />}
							</div>} position={BP.Position.BOTTOM_LEFT}>
							<E5XYChart leftsource={E5CBDashboard.GetScores(global.healthmetmap, E5CBOverview)}
								rightsource={{}} title={_("cb-health-over-time")} loading={false}
								xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }}
								clickcb={E5CBDashboard.HealthClick} height={350} normaltraceorder withNewComponent chartOption={{ filled: true, newColorScheme: true }} />
						</BP.Popover>
					</div>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">
					<E5PieChart pieinfo={{
						title: _("cb-overview-pie-softwarev"), valueisseconds: false, labelisincident: false,
						ids: sfwids, parents: sfwparents, values: sfwvalues, labels: sfwlabels, loading: false
					}} chartOption={{ type: 'sunburst', colors: ['#039BE5', '#29B6F6', '#68CBF8'] }} withNewComponent />
				</div>
				<div className="e5linefull2 e5line-20">
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-overview-pie-equips"), valueisseconds: false, labelisincident: false,
							ids: eqpids, parents: eqpparents, values: eqpvalues, labels: eqplabels, loading: false
						}} withNewComponent chartOption={{ colors: ['#FF8A65', '#F06292'] }} />
					</div>
					<div className="e5linefull">
						<E5PieChart pieinfo={{
							title: _("cb-overview-pie-stations"), valueisseconds: false, labelisincident: false,
							ids: staids, parents: staparents, values: stavalues, labels: stalabels, loading: false
						}} withNewComponent chartOption={{ colors: ['#AED581', '#7EE8F6', '#F472B6', '#FED86E'] }} />
					</div>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBOverview.GetGrowthEquips()} rightsource={{}}
						title={_("cb-overview-growth-equips")} loading={false} height={350} baseYaxis
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} withNewComponent chartOption={{ filled: false, colors: ['#FF8A65', '#F06292'], type: 'bar' }} />
				</div>
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBOverview.GetGrowthStations()} rightsource={{}}
						title={_("cb-overview-growth-stations")} loading={false} height={350} baseYaxis
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }} withNewComponent chartOption={{ filled: false, colors: ['#FF8A65', '#F06292'], type: 'bar' }} />
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">
					<E5XYChart leftsource={E5CBOverview.GetEquip()} rightsource={{}} title={_("cb-overview-equip")}
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }}
						loading={false} height={350} withNewComponent chartOption={{ filled: false, type: 'bar' }} />
				</div>
			</div>
		</div>;
	}

	// ---------------- UTILS ----------------

	//E5
	static GetGrowthEquips: () => E5XYSource = (): E5XYSource => {
		let src: E5XYSource = { numdatas: undefined, options: { stacked: false, bar: true, barstack: true } };
		src.numdatas = [];
		let eqpmap: Map<string, E5XYNumData> = new Map(), numdata: E5XYNumData | undefined, { global } = E5StoreCB.Ins();
		if (global.equipmap !== null && global.equipmap !== undefined)
			for (let [date, clamap] of global.equipmap) for (let [eqptype, count] of clamap) {
				numdata = eqpmap.get(eqptype);
				if (numdata === undefined) {
					numdata = {
						xaxisdata: [], yaxisdata: [],
						datalabel: E5UtilI18n._("wificb-indic-" + eqptype.toLowerCase())
					};
					eqpmap.set(eqptype, numdata);
				}
				numdata.xaxisdata.push(date);
				numdata.yaxisdata.push(count);
			}
		for ([, numdata] of eqpmap) {
			if (numdata.datalabel !== 'STB') {
				src.numdatas.push(numdata)
			}
		};
		return src;
	};

	//E5
	static GetGrowthStations: () => E5XYSource = (): E5XYSource => {
		let src: E5XYSource = { numdatas: undefined, options: { stacked: false, bar: true, barstack: true } };
		src.numdatas = [];
		let stamap: Map<string, E5XYNumData> = new Map(), numdata: E5XYNumData | undefined, { global } = E5StoreCB.Ins();
		if (global.stationmap !== null && global.stationmap !== undefined)
			for (let [date, clamap] of global.stationmap) for (let [band, count] of clamap) {
				numdata = stamap.get(band);
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._(band) };
					stamap.set(band, numdata);
				}
				numdata.xaxisdata.push(date);
				numdata.yaxisdata.push(count);
			}
		for ([, numdata] of stamap) src.numdatas.push(numdata);
		src = removeGaps(src);
		return src;
	};

	//E5
	static GetEquip: () => E5XYSource = (): E5XYSource => {
		let src: E5XYSource = { numdatas: undefined, options: { stacked: false, bar: true, barstack: true } };
		src.numdatas = [];
		let stamap: Map<string, E5XYNumData> = new Map(), numdata: E5XYNumData | undefined, { global } = E5StoreCB.Ins();
		if (global.maxavgmap !== null && global.maxavgmap !== undefined)
			for (let [date, clamap] of global.maxavgmap) for (let [bandkey, val] of clamap) {
				let [band, kind] = bandkey.split(";"), is2ghz: boolean = band === E5BandEnum.freq2ghz;
				numdata = stamap.get(bandkey);
				if (numdata === undefined) {
					numdata = {
						xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._("wificb-indic-bandavg-" + (is2ghz ? "2ghz" : band.toLowerCase())), color: E5CBOverview.GetBarColor(band)
					};
					stamap.set(bandkey, numdata);
				}
				numdata.xaxisdata.push(date);
				numdata.yaxisdata.push(val);
			}
		if (global.equipmap !== null && global.equipmap !== undefined)
			for (let [date, eqpmap] of global.equipmap) {
				numdata = stamap.get("ext");
				if (numdata === undefined) {
					numdata = { xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._("wificb-indic-extperh"), color: E5CBOverview.GetBarColor("ext")};
					stamap.set("ext", numdata);
				}
				numdata.xaxisdata.push(date);
				numdata.yaxisdata.push(E5Text.RoundUp((eqpmap.get("EXT") ?? 0) / (eqpmap.get("GW") ?? 1)));
				numdata = stamap.get("stb");
				// if (numdata === undefined) {
				// 	numdata = {xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._("wificb-indic-stbperh")};
				// 	stamap.set("stb", numdata);
				// }
				// numdata.xaxisdata.push(date);
				// numdata.yaxisdata.push((eqpmap.get("STB") ?? 0) / (eqpmap.get("GW") ?? 1));
			}
		for ([, numdata] of stamap) src.numdatas.push(numdata);
		src = removeGaps(src);
		return src;
	};

	static GetBarColor(band: string): string {
		switch (band) {
			case E5BandEnum.freq2ghz:
				return DEFAULT_CHART_COLORS[0];
			case E5BandEnum.freq5ghz:
				return DEFAULT_CHART_COLORS[1];
			case E5BandEnum.freq6ghz:
				return DEFAULT_CHART_COLORS[2];
			case E5BandEnum.eth:
				return DEFAULT_CHART_COLORS[3];
			case 'ext':
				return DEFAULT_CHART_COLORS[4];
			default:
				return DEFAULT_CHART_COLORS[5];
		}
	}

	static GetSoftwarePieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let { global } = E5StoreCB.Ins(), lasteqpdetmet: Map<string, number> | undefined = undefined,
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			const hasData = !![...global.eqpdetailmap?.entries() || []]?.length
			if (global.eqpdetailmap && hasData)
				lasteqpdetmet = [...global.eqpdetailmap.entries()][global.eqpdetailmap.size - 1][1];
			if (global.eqpdetailmap === null || !hasData) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (global.eqpdetailmap !== undefined && lasteqpdetmet !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				let modelset: Set<string> = new Set(), manufset: Set<string> = new Set();
				for (let [detkey, count] of lasteqpdetmet) {
					let [manuf, model, sofv] = detkey.split(";");
					if (manuf === "") manuf = "N/A";
					if (model === "") model = "N/A";
					if (sofv === "") sofv = "N/A";
					if (!modelset.has(model + manuf)) {
						modelset.add(model + manuf);
						if (!manufset.has(manuf)) {
							manufset.add(manuf);
							ids.push(manuf);
							parents.push("total");
							values.push(0);
							labels.push(manuf);
						}
						ids.push(model + manuf);
						parents.push(manuf);
						values.push(0);
						labels.push(model);
					}
					ids.push(sofv + ";" + model + ";" + manuf);
					parents.push(model + manuf);
					values.push(count);
					labels.push(sofv);
				}
			}
			return [ids, parents, values, labels];
		};

	static GetEquipPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let { global } = E5StoreCB.Ins(), lasteqpmet: Map<string, number> | undefined = undefined,
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			const hasData = !![...global.equipmap?.entries() || []]?.length
			if (global.equipmap && hasData)
				lasteqpmet = [...global.equipmap.entries()][global.equipmap.size - 1][1];
			if (global.equipmap === null || !hasData) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (global.equipmap !== undefined && lasteqpmet !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				for (let [eqptype, count] of lasteqpmet) {
					if (eqptype !== 'STB') {
						ids.push(eqptype === "" ? "N/A" : eqptype);
						parents.push("total");
						values.push(count);
						labels.push(eqptype === "" ? "N/A" : eqptype);
					}

				}
			}
			return [ids, parents, values, labels];
		};

	static GetStationPieData: () => [string[], string[], number[], string[]] =
		(): [string[], string[], number[], string[]] => {
			let { global } = E5StoreCB.Ins(),
				ids: string[] = [], parents: string[] = [], values: number[] = [], labels: string[] = [];
			if (global.statypemap === null) {
				ids = ["N/A"];
				parents = [""];
				values = [0];
				labels = ["N/A"];
			} else if (global.statypemap !== undefined) {
				ids = ["total"];
				parents = [""];
				values = [0];
				labels = [E5UtilI18n._("total")];
				for (let [statype, count] of global.statypemap) {
					ids.push(statype === "" ? "N/A" : statype);
					parents.push("total");
					values.push(count);
					labels.push(statype === "" ? "N/A" : statype);
				}
			}
			return [ids, parents, values, labels];
		};
});
